@import '../../baseSCSS/variables';

.login__div {
  position: absolute;
  top: 4vh;
  left: 55%;
  height: 80vh;
  width: 35% !important;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgb(0 0 0 / 20%);
  border-radius: 5px;
  @media (max-width: $max-screen-width) {
    position: static;
    width: 100% !important;
    height: 65vh;
    box-shadow: unset;
  }
  @media screen and (max-height: $custom-max-height) {
    height: auto;
  }
}

.login__container {
  height: 75vh;
  max-height: 75vh;
  @media (max-width: $min-screen-width) {
    height: 90vh;
    max-height: 100vh;
  }
  @media (max-width: $mobile-screen-large-width) {
    height: 72vh;
  }
}
