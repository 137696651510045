@import "../../../baseSCSS/variables";

.login__recaptcha {
  padding-left: 20%;
  margin-top: 4%;
  max-width: 100px;
  @media (max-width: $min-screen-width) {
    padding-left: 6%;
  }
}

.grecaptcha-badge {
  margin-bottom: 15px !important;
}
