.login__forgotDetails {
  margin-top: 3px;
}

.login__forgotDetails__anchorTag {
  text-decoration: none;
  cursor: pointer;
  font-size: small;
}

.login__forgotDetails__column__username {
  text-align: left;
}

.login__forgotDetails__column__password {
  text-align: right;
}
