* {
  shape-rendering: geometricPrecision;
}

.indicator {
  height: 4px;
  border-radius: 4px;
  width: 15%;
  margin-right: 8px;
}

.indicator-container {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
}
