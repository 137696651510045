.forgotPasswordReset__restorePasswordBtn {
  width: 100%;
  background: #0a2b47 !important;
  border-radius: 5px !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.forgotPasswordReset__passwordRepeatLabel,
.forgotPasswordReset__passwordLabel {
  font-size: 12px;
}

.forgotPasswordReset__backToLoginDiv {
  text-align: center;
  margin-top: 10px;
}

.forgotPasswordReset__loader {
  display: flex;
  justify-content: center;
  margin-right: 45px;
}

.forgotPasswordReset__message {
  & > small {
    color: #0a2b47 !important;
    text-decoration: none;
  }
  text-align: center;
}

.forgotPasswordReset__error {
  & > small {
    color: red !important;
    text-decoration: none;
  }
}

.forgotPasswordReset__backToLogin {
  text-decoration: none;
  cursor: pointer;
  font-size: small;
}

.forgotPasswordReset__backToLoginContainer {
    text-align: center;
}
