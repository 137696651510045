@import '../../../baseSCSS/variables';

.login__form{
  width: 70% !important; 
  padding: 20px;
  background: #FFFFFF;
  // box-shadow: 0px 1px 4px #E5E9F2;
  border-radius: 5px;
  margin-left:10%;
  @media (max-width: $min-screen-width) {
    width: 100% !important; 
    margin-left:0%;
  }
}

.login__resetPasswordDiv,
.login__signInDiv{
  margin-bottom: 50px;
  font-size: 3em;
  margin-left: 8% !important;
  margin-top: 12% !important;
  color: #0A2B47;
  @media (max-width:$min-screen-width) {
    margin-bottom: 10px;
    margin-top: 6% !important;
  }
}

.login__userNameLabel, .login__passwordLabel{
  font-size: 12px;
}

.login__signInBtn{
  width: 100%;
  background: #0A2B47 !important;
  border-radius: 5px !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: white;
  &:hover{
  color: white;
  }
  &:focus{
  color: white;
 }
 &:click{
  color: white;
 }
}