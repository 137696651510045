.PrivacyPolicy__container {
}

.PrivacyPolicy__title {
    font-weight: 600;
    font-size: 15px;
}

.PrivacyPolicy__description {
    font-weight: 400;
    font-size: 14px;
}