@use "../../../baseSCSS/animations";
@import "../../../baseSCSS/variables";

.navigation__Background {
  width: 100%;
  height: 100vh;
  background: #4ab5d8;
  overflow: hidden;
  padding: 50px;
  @media (max-width: $max-screen-width) {
    padding: 0px;
  }
}

.navigation__mainview {
  height: 90vh;
}

.navigation__TabHeader {
  border: none;
}

.navigation__LinkItemHeader {
  color: white;
  height: 25vh;
}

.navigation__Logo {
  width: 200px;
  @media (max-width: $max-screen-width) {
    width: 100%;
    height: 5vh;
  }
}

.navigation__LinkItemHeader__Title {
  font-weight: 300;
  word-break: break-all;
  @media (max-width: $max-screen-width) {
    font-weight: 400;
    color: #ffffff;
  }
}

.navigation__LinkItem {
  height: 75px;
  position: relative;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Alata", sans-serif;
  font-weight: 500;
  @media (max-width: $max-screen-width) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px !important;
    margin-bottom: 0px !important;
  }
}

.navigation__LinkItem_AddonTop {
  width: 10px;
  height: 10px;
  background-color: white;
  margin-left: auto;
}

.navigation__LinkItem_AddonTopInside {
  width: 10px;
  height: 10px;
  background-color: #4ab5d8;
  margin-left: auto;
  border-bottom-right-radius: 50px !important;
}

.navigation__LinkItem_AddonBottom {
  width: 10px;
  height: 10px;
  background-color: white;
  margin-left: auto;
}

.navigation__LinkItem__Title {
  text-align: left;
  padding-left: 20px;
}

.navigation__LinkItem_AddonBottomInside {
  width: 10px;
  height: 10px;
  background-color: #4ab5d8;
  margin-left: auto;
  border-top-right-radius: 50px !important;
}

.navigation__TabContent {
  border-radius: 50px;
}

.navigation__TabContent__CardBody {
  height: 85vh;
  border-radius: 50px;
  padding: 50px;
  border-color: white;
  overflow: hidden;
  @media (max-width: $min-screen-width) {
    padding: 10px;
    border-radius: 10px;
    max-height: 75vh;
    overflow: auto;
  }
}

.navigation__TabContent__Widgets {
  text-align: center;
}

.navigation__TabContent__Transactions {
  text-align: center;
  margin-top: 10px;
  flex-grow: 1;
}

.navigation__TabContent__Dashboard__Datatable .rdt_Table {
  @media only screen and (max-width: $mobile-large-width) {
    max-height: 230px !important;
    overflow: auto;
  }
  @media only screen and (min-width: $min-screen-width) {
    max-height: 45vh !important;
    overflow: auto;
  }
}

.navigation__TabContent__Transaction__Datatable .rdt_Table {
  @media only screen and (max-width: $min-screen-width) {
    max-height: 55vh !important;
    overflow: auto;
  }
  @media only screen and (min-width: $min-screen-width) {
    max-height: 60vh !important;
    overflow: auto;
  }
}

.navigation__TabContent__Container {
  position: relative;
}

.navigation__TabContent__AccountDetailsContainer {
  position: absolute;
  padding: 20px;
  right: 60px;
  top: 20px;
  z-index: 10000;
  overflow: hidden;
  @media (max-width: $max-screen-width) {
    right: 25px;
    top: 5px;
  }
}

.navigation__TabContent__Initial {
  display: none;
}

.navigation__TabContent__Show {
  margin-top: 10px;
  @extend .slide-in-blurred-top;
}

.navigation__TabContent__Hide {
  @extend .slide-out-blurred-top;
}

.navigation__TabContent__SettingsIcon {
  text-align: right;
  cursor: pointer;
}

.navigation__mobile__navMenu {
  margin-top: 10px !important;
}

.navigation__TabContent__Dashboard__LoanDiv {
  margin-top: 25px;
  align-items: flex-end;
  @media (max-width: $max-screen-width) {
    text-align: center;
  }
}

.navigation__TabContent__Dashboard__LoanDiv_recentTransactionText {
  font-family: "Alata", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  color: #42427d;
}

.navigation__TabContent__Dashboard__LoanDiv_transactionInfoText {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 0px;
  @media (max-width: $max-screen-width) {
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

.navigation__LinkItem__supportDdl {
  margin-right: 15px;
}

.navigation__TabContent__CardBody__Transaction__Heading {
  font-family: "Allerta", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #32325d;
}

.navigation__TabContent__CardBody__Dashboard__Heading {
  font-family: "Allerta", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #32325d;
  margin-top: 18px;
}

.navigation__SupportCard {
  border: 2px solid #4ab5d8;
  border-radius: 20px;
  height: 128px;
  margin-top: 8px;
}

.navigation__SupportCard:hover {
  opacity: 0.7;
}
