.forgotPassword__restorePasswordBtn {
  width: 100%;
  background: #0a2b47 !important;
  border-radius: 5px !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.forgotPassword__userNameLabel {
  font-size: 12px;
}

.forgotPassword__backToLoginDiv {
  text-align: center;
  margin-top: 10px;
}

.forgotPassword__loader {
  display: flex;
  justify-content: center;
  margin-right: 45px;
}

.forgotPassword__message {
  & > small {
    color: #0a2b47 !important;
    text-decoration: none;
  }
  text-align: center;
}

.forgotPassword__error {
    & > small {
        color: red !important;
        text-decoration: none;
      }
}
