.loader__Container {
  width: 300px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader__ProgressBar {
  height: 4px;
  background-color: rgba(74, 181, 216, 0.2);
  width: 100%;
  overflow: hidden;
}

.loader__ProgressBarValue {
  width: 100%;
  height: 100%;
  background-color: rgba(74, 181, 216, 1);
  animation: indeterminateAnimation 0.5s infinite linear;
  transform-origin: 0% 50%;
}

.loader_Logo {
    width: 75px;
    margin: 30px;
}

.loader__Error {
  color: red;
}

.loader__Error:hover {
  text-decoration: none;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
