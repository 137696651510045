.forgotUsername__restoreUsernameBtn {
  width: 100%;
  background: #0a2b47 !important;
  border-radius: 5px !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.forgotUsername__phoneNumberLabel {
  font-size: 12px;
}

.forgotUsername__backToLoginDiv {
  text-align: center;
  margin-top: 10px;
}

.forgotUsername__loader {
  display: flex;
  justify-content: center;
  margin-right: 45px;
}


