@import '../../../baseSCSS/variables';


.rdt_TableRow{
    cursor: pointer;
}
.navigation__TabContent__Transactions .rdt_TableRow{
  cursor: initial;
}

.rdt_TableCell {
    font-family: "Allerta";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #525f7f;
  }
  
  .rdt_TableCol {
    font-family: "Allerta";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.9px;
    color: #8898aa;
  }

  .rdt_Pagination {
    font-family: "Allerta";
  }