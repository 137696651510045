.accountDetails__Logout {
  cursor: pointer;
}

.accountDetails__Container {
  box-shadow: rgba(10, 43, 71, 0.6) 0px 0px 0px 3px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.accountDetails__NoSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accountDetails__Heading {
    font-weight: 600;
}
