@import "../../../baseSCSS/variables";

.login__tabitMapleLeafContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 30vh;
  background-repeat: no-repeat;
  background-position: left;
  left: 75px;
  right: 125px;
  max-width: 500px;
  @media (max-width: $max-screen-width) {
    background-size: contain;
    visibility: hidden;
  }
  @media (min-width: $min-screen-width) and (max-width: $max-screen-width) {
    max-width: 40%;
  }
}

.login_tabitMapleLeafText {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #15456e;
}

.login_tabitMapleLeafImg {
  max-width: 250px;
}
