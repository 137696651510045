@import "../../../baseSCSS/variables";

.header__TopSection {
  width: 100%;
  height: 10vh;
  background: #4ab5d8;
  @media (max-width: $min-screen-width) {
    display: none;
  }
}

.header__BottomSection {
  width: 100%;
  height: 10vh;
  background: #0a2b47;
  display: flex;
  align-items: center;
}

.header__BottomSection_Image {
  height: 4.5rem;
  @media (max-width: $min-screen-width) {
    height: 4rem;
  }
}

.header__tabitIcon__row{
  background-color: #0A2B47;
}

.header__tabitIcon {
  height: 10vh;
  background: url(../../../assets/tabit-login-logo.svg);
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 20px;
  background-color: #0A2B47;  
  min-height: 86px;
  @media (max-width: $min-screen-width) {
    width: 80%;
  }
  @media (min-width: $min-screen-width) and (max-width: $max-screen-width) {
    width: 80%;
  }
}
