@use "../../../baseSCSS/animations";

.CustomModal__window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;

  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.CustomModal__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  cursor: pointer;
}

.CustomModal__container {
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.CustomModal__sm {
  width: 350px;
}

.CustomModal__md {
  width: 450px;
}

.CustomModal__lg {
  width: 600px;
}

.CustomModal__content {
  color: #0a2b47;
  overflow-y: auto;
  max-height: 500px;
}

.CustomModal__none {
  display: none;
}

.CustomModal__title {
  color: #0a2b47;
}
