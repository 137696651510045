$max-width-size: 576px;

.home-body-cardList-div {
  font-family: "Alata", sans-serif;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  @media (max-width: $max-width-size) {
    display: flex;
    flex-wrap: wrap;
  }
}

.home-body-card-div {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $max-width-size) {
    width: 100%;
  }
}
