@import '../../../baseSCSS/variables';

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-size: small;
  align-items: flex-end;
  @media (max-width: $min-screen-width) {
    justify-content: center;
  }
}

.footer__followUsDiv {
  font-weight: bold;
  margin-left: 5px;
}

.footer__followBlock {
  @media (max-width: $min-screen-width) {
    display: none;
  }
}

.login__Footer {
  color: darkslategrey;
}

.footer__socialMediaSpan {
  margin: 5px;
  cursor: pointer;
}

.footer__legalLink {
    cursor: pointer;
}

.dashboard__Footer {
  color: white;
  background-color: #4ab5d8;
}
